<template>
    <Header/>
    <main class="content">
      <HeroFrontpage :data="data.data?.attributes.hero"/>
      <component
          v-for="(block, index) in data.data?.attributes.body"
          :key="index"
          :index="parseInt(index)"
          :is="getComponentName(block)"
          :data="block"
      />
    </main>
    <Footer/>
</template>

<script lang="ts">
import componentImports from "../assets/js/componentImports.js";

export default {
  components: {
    ...componentImports,
  },
};
</script>

<script setup lang="ts">
  import {useFetch} from "nuxt/app";
  import Header from "../components/Header.vue";
  import Footer from "../components/Footer.vue";

  const runtimeConfig = useRuntimeConfig();
  const { data, pending } = await useFetch(runtimeConfig.public.STRAPI_API_URL + '/frontpage?pLevel', {
     headers: {
      "Strapi-Response-Format": "v4",
    }
  });

  const getComponentName = ((block: any) => {
    let blockName = block.__component.replace("blocks.", "");
    return blockName.split('-')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('');
  });

  const seoData = data?.value?.data?.attributes?.seo || {};
const { data: globalSeoData } = await useFetch(runtimeConfig.public.STRAPI_API_URL + '/global-seo-setting?pLevel', {
   headers: {
      "Strapi-Response-Format": "v4",
    }
});
const seoDataNoIndex = seoData.noIndex ?? false;
const seoDataTitle = seoData.pageTitle;
const seoDataDescription = seoData.shortDescription;
const seoDataCanonicalUrl = seoData.canonicalURL
const seoDataOgTitle = seoData.ogTitle ?? seoDataTitle
const seoDataOgDescription = seoData.ogDescription  ?? seoDataDescription
const seoDataOgImage = seoData?.image?.data ?? globalSeoData?.value?.data?.attributes?.ogImage.data;
const seoDataOgImageUrl = seoDataOgImage.attributes.url;



useSeoMeta({
  title: () => seoDataTitle,
  ogTitle: () => seoDataOgTitle,
  description: () => seoDataDescription,
  ogDescription:  () => seoDataOgDescription,
  ogImage: () => seoDataOgImageUrl,
})

useHead({
  meta: [
    seoDataNoIndex != false ? { name: 'robots', content: 'noindex, follow' } : {},
  ],
  script: [
    seoData.Schema != null ? { type: 'application/ld+json', innerHTML: seoData.Schema } : {},
    globalSeoData.value.data.attributes.globalSchema != {} ? { type: 'application/ld+json', innerHTML: globalSeoData.value.data.attributes.globalSchema } : {},
  ]
})

</script>
